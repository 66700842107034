var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "flex mv2 justify-between" }, [
      _c("div", { staticClass: "flex items-center" }, [
        !_vm.autoPublish
          ? _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap justify-around items-center darker-hover link pointer ph1 pv1-5 br2 ba mr2 mb2 mt2",
                class:
                  _vm.currentCollection === "AUDIOGRAM_IN_TRACK"
                    ? "bg-black-30 b--adori-red"
                    : "b--adori-gray",
                on: {
                  click: function () {
                    return _vm.selectCollection("AUDIOGRAM_IN_TRACK")
                  },
                },
              },
              [
                _c("span", { staticClass: "light-gray f7 mh1" }, [
                  _vm._v("Audiogram in this track "),
                ]),
                _c("span", { staticClass: "light-gray f7 mh1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.audiogramInTrackLength) +
                      "\n        "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "flex flex-wrap justify-around items-center darker-hover link pointer ph2 pv1-5 br2 ba mr2 mb2 mt2",
            class:
              _vm.currentCollection === "ALL_AUDIOGRAMS"
                ? "bg-black-30 b--adori-red"
                : "b--adori-gray",
            on: {
              click: function () {
                return _vm.selectCollection("ALL_AUDIOGRAMS")
              },
            },
          },
          [
            _c("span", { staticClass: "light-gray f7 mh1" }, [
              _vm._v("All audiograms"),
            ]),
            _c("span", { staticClass: "light-gray f7 mh1" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.audiogramList.length) + "\n        "
              ),
            ]),
          ]
        ),
      ]),
      _vm.currentCollection === "ALL_AUDIOGRAMS"
        ? _c("div", { staticClass: "items-center pv1-5" }, [
            _c(
              "select",
              {
                staticClass:
                  "bg-adori-light-gray white f6 ph3 br2 h2 ba b--gray",
                domProps: { value: _vm.orientation },
                on: { change: _vm.changeOrientation },
              },
              _vm._l(_vm.filterOptions, function (item, i) {
                return _c("option", { key: i, domProps: { value: item } }, [
                  _vm._v("\n          " + _vm._s(item) + "\n        "),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm.isAudiogramLoading
      ? _c(
          "div",
          { staticClass: "flex flex-wrap ml3 mt3" },
          _vm._l(new Array(50), function (_, index) {
            return _c("div", {
              key: index,
              staticClass:
                "w-100 bb bw1 b--adori-gray flex justify-center items-center mr3 mb3 br2 skeleton",
              class: {
                "landscape-size": _vm.orientation === "LANDSCAPE",
                "square-size": _vm.orientation === "SQUARE",
                "portrait-size": _vm.orientation === "PORTRAIT",
                "bg-adori-gray": _vm.$router.currentRoute.fullPath === "/tags",
                "bg-adori-light-gray":
                  _vm.$router.currentRoute.fullPath !== "/tags",
              },
            })
          }),
          0
        )
      : _c("div", [
          _vm.currentCollection === "ALL_AUDIOGRAMS"
            ? _c("div", [
                _vm.audiogramList.length
                  ? _c("div", { staticClass: "w-100 br2 white pb4" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap ml3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 cb-shadow flex flex-column justify-center items-center mr3 mb3 br2 new-audiogram b--adori-gray ba noselect",
                              class: {
                                "landscape-size":
                                  _vm.orientation === "LANDSCAPE",
                                "square-size": _vm.orientation === "SQUARE",
                                "portrait-size ":
                                  _vm.orientation === "PORTRAIT",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editAudiogram()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "nodrag",
                                attrs: {
                                  src: require("@/assets/audiogram/template-active.svg"),
                                },
                              }),
                              _c("div", { staticClass: "text silver mt1" }, [
                                _vm._v("New Audiogram"),
                              ]),
                            ]
                          ),
                          _vm._l(_vm.audiogramList, function (audiogram) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "relative flex mr3 mb3 w4 hide-child",
                                class: {
                                  "landscape-size":
                                    _vm.orientation === "LANDSCAPE",
                                  "square-size": _vm.orientation === "SQUARE",
                                  "portrait-size":
                                    _vm.orientation === "PORTRAIT",
                                },
                                attrs: { id: "audiograms" },
                              },
                              [
                                _c("img", {
                                  staticClass: "br2 nodrag w-100 tinted",
                                  class: _vm.selectionBorder(audiogram.id),
                                  attrs: { src: audiogram.image.thumbnailURL },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "smaller flex flex-column justify-around absolute child",
                                    staticStyle: { top: "0px", right: "5px" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                        attrs: { title: "Edit audiogram" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAudiogram(
                                              audiogram.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons light-gray f4 v-mid pa1",
                                          },
                                          [_vm._v(" edit ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                        attrs: { title: "Delete audiogram" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteAudiogram(
                                              audiogram.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons light-gray f4 v-top pa1",
                                          },
                                          [_vm._v(" delete ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                        attrs: { title: "Duplicate audiogram" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAudiogram(
                                              audiogram.id,
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons light-gray f4 v-mid pa1",
                                          },
                                          [_vm._v(" control_point_duplicate ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "absolute noselect top-0 left-0",
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons f3 white mt1 ml1 relative text-shadow",
                                        class:
                                          _vm.selectionStyle(audiogram.id) +
                                          " pointer",
                                        on: {
                                          mousedown: function ($event) {
                                            return _vm.selectAudiogram(
                                              audiogram.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectionIcon(audiogram.id)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm.audiogramList.length === 0
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "empty" },
                        [
                          _c("img", {
                            attrs: {
                              width: "150px",
                              alt: "placeholder",
                              src: require("@/assets/audiogram/no-data.svg"),
                            },
                          }),
                          _c("div", { staticClass: "text white" }, [
                            _vm._v(
                              "\n            You've not added any " +
                                _vm._s(_vm.filterText) +
                                " "
                            ),
                            _c("strong", [_vm._v("audiogram")]),
                            _vm._v("\n            yet\n          "),
                          ]),
                          _c("span", [
                            _vm._v("Would you like to add one now?"),
                          ]),
                          _c("BaseButtonRed", {
                            staticClass: "gram-btn",
                            attrs: {
                              text: "Create audiogram",
                              onClick: _vm.editAudiogram,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.hasAudiogramNextPage
                  ? _c(
                      "div",
                      { staticClass: "tc pb3 ph4 mt3" },
                      [
                        _c("BaseButtonLoadMore", {
                          attrs: {
                            text: "Load more",
                            onClick: _vm.nextAudiogramPage,
                            rootBottomMargin: "800",
                            onVisible: function () {},
                            loading: _vm.isNextAudiogramFetching,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.currentCollection === "AUDIOGRAM_IN_TRACK"
            ? _c("div", [
                _vm.audiogramInTrackLength
                  ? _c(
                      "div",
                      {
                        staticClass: "relative flex mr3 mb3 w4 hide-child",
                        class: {
                          "landscape-size":
                            _vm.audiodramInTrackOrientation === "LANDSCAPE",
                          "square-size":
                            _vm.audiodramInTrackOrientation === "SQUARE",
                          "portrait-size":
                            _vm.audiodramInTrackOrientation === "PORTRAIT",
                        },
                        attrs: { id: "audiograms" },
                      },
                      [
                        _c("img", {
                          staticClass: "br2 nodrag w-100",
                          attrs: { src: _vm.audiogramInTrackImg },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "smaller flex flex-column justify-around absolute child",
                            staticStyle: { top: "0px", right: "5px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Edit audiogram" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAudiogram(
                                      _vm.audiogramInTrackId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid pa1",
                                  },
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Remove audiogram" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAudiogram(
                                      _vm.audiogramInTrackId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-top pa1",
                                  },
                                  [_vm._v(" clear ")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c("div", [_vm._m(0)]),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty" }, [
      _c("img", {
        attrs: {
          width: "150px",
          alt: "placeholder",
          src: require("@/assets/audiogram/no-data.svg"),
        },
      }),
      _c("div", { staticClass: "text white" }, [
        _vm._v("You've not added any audiogram on this track"),
      ]),
      _c("span", [_vm._v("Go to All audiograms tab to select one ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }