

























































































































































































import draggable from 'vuedraggable'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

import { orientationStatus } from '@/components/Publish/publish'
import { computed, SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { getQueryParam } from '@/utils/misc'
import { audiogramQueryKeys, useGetAudiograms, useGetTrackAudiograms } from '@/hooks/audiogram'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {
    draggable,
  },
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const trackUid: any = getQueryParam('uid')
    const orientation = computed(() => props.orientation)
    const isTrackEnabled = computed(() => !!trackUid)
    const { data: trackAudiograms, isFetching: isTrackAudiogramsLoading } = useGetTrackAudiograms(trackUid, {
      enabled: isTrackEnabled,
    })
    const {
      data: audiogramData,
      fetchNextPage: nextAudiogramPage,
      isFetchingNextPage: isNextAudiogramFetching,
      isLoading: isAudiogramLoading,
      hasNextPage: hasAudiogramNextPage,
    } = useGetAudiograms(orientation)
    return {
      queryClient,
      audiogramData,
      nextAudiogramPage,
      isNextAudiogramFetching,
      isAudiogramLoading,
      hasAudiogramNextPage,
      trackAudiograms,
      isTrackAudiogramsLoading,
    }
  },
})
export default class TheAudiogramGrid extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Array) audiogramInTrack!: any[]
  @Prop(Boolean) autoPublish!: boolean
  @Prop(String) orientation!: string

  @Getter networkId!: any
  @Getter audiogramById!: any
  @Getter audiogramInAudioTrack!: any

  @Action pushNotification!: any

  isLoadingAudioGrams = false

  filterOptions: any = ['LANDSCAPE', 'SQUARE', 'PORTRAIT']
  currentCollection = 'ALL_AUDIOGRAMS'

  currentWindowY: number = 0
  currentClientY: number = 0
  draggedAudiogramPosition: any = {}

  queryClient!: QueryClient
  audiogramData!: any
  nextAudiogramPage!: boolean
  isNextAudiogramFetching!: boolean
  isAudiogramLoading!: boolean
  hasAudiogramNextPage!: boolean

  async created() {
    this.$store.commit('unselectAudiogram')
    this.$store.commit('unsetAudiogramInAudioTrack')
    if (get(this.audiogramInTrack, '[0]'))
      this.$store.dispatch('initialSelectedAudiogram', this.audiogramInTrack[0].audiogramId)
  }

  selectCollection(name: string) {
    this.currentCollection = name
  }

  changeOrientation(event: any) {
    this.$emit('setFilter', event.target.value)
  }

  get audiogramInTrackLength() {
    return Object.keys(this.audiogramInAudioTrack).length ? 1 : 0
  }

  get audiogramInTrackImg() {
    return this.audiogramInAudioTrack.image.thumbnailURL
  }

  get audiodramInTrackOrientation() {
    return this.audiogramInAudioTrack.orientation
  }

  get audiogramInTrackId() {
    return this.audiogramInAudioTrack.id
  }

  selectionIcon(id: string) {
    return this.$store.getters.isAudiogramSelected(id) ? 'check_circle' : 'radio_button_unchecked'
  }

  selectionStyle(id: string) {
    return this.$store.getters.isAudiogramSelected(id) ? 'adori-red' : 'o-80'
  }
  selectionBorder(id: string) {
    return this.$store.getters.isAudiogramSelected(id) ? 'b--adori-red ba' : ''
  }

  get audiogramList() {
    return get(this, 'audiogramData', []).filter((audiogram: any) => audiogram.orientation === this.orientation)
  }

  editAudiogram(audiogramId?: string, isDuplicate = false) {
    if (!audiogramId) {
      this.$store.dispatch('audiogramOpenFrom', this.orientation)
    }
    this.$store.dispatch('showAudiogramEdit', audiogramId)
    this.$store.dispatch('setIsDuplicate', isDuplicate)
  }

  deleteAudiogram(audiogramId: string) {
    this.$store.dispatch('showConfirm', {
      title: 'Delete audiogram?',
      description: 'You are about to permanently delete the selected audiogram. Are you sure?',
      yesButtonText: 'Yes, delete this audiogram',
      noButtonText: 'No, keep this audiogram',
      onConfirm: async () => {
        this.isLoadingAudioGrams = true
        // check if audiogram is present on timeline
        if (get(this.audiogramInTrack, '[0].audiogramId', '') === audiogramId) {
          this.$store.dispatch('removeAudioAudiogram', {
            audioUid: this.audioUid,
            audiogramPosition: {
              ...this.audiogramInTrack[0],
            },
          })
          this.$store.commit('unselectAudiogram')
          this.$store.commit('unsetAudiogramInAudioTrack')
        }
        await this.$store.dispatch('deleteAudiogram', audiogramId)
        this.queryClient.invalidateQueries([audiogramQueryKeys.GET_AUDIOGRAMS, this.networkId, this.orientation])
        await this.$store.dispatch('closeModal')
        this.isLoadingAudioGrams = false
      },
    })
  }

  async selectAudiogram(id: string) {
    const res = await this.$store.dispatch('toggleSelectAudiogram', {
      audiogramId: id,
      audiogram: this.audiogramById[id],
    })
    if (res) {
      if (this.autoPublish) {
        this.pushNotification({
          text: 'Audiogram selected for this podcast',
          type: 'SUCCESS',
        })
      } else {
        const item = { audiogramId: id, offsetMillis: 0 }
        await this.$store.dispatch('addAudioAudiogram', {
          audioUid: this.audioUid,
          audiogramPosition: item,
        })
        this.$gtag.event('add-audiogram', {
          action: 'Audiogram attached successfully',
        })
        mixpanel.track('Add Audiogram')
        webengage?.track('add-audiogram', {
          audioUid: this.audioUid,
          audiogramPosition: item,
        })
        this.queryClient.invalidateQueries([audiogramQueryKeys.GET_AUDIOGRAMS, this.networkId, this.orientation])
        this.pushNotification({
          text: 'Audiogram attached to track successfully',
          type: 'SUCCESS',
        })
      }
    } else {
      this.removeAudiogram(id)
    }
  }

  async removeAudiogram(audiogramId: string) {
    if (this.autoPublish) {
      this.$store.commit('unselectAudiogram')
      this.pushNotification({
        text: 'Audiogram removed from podcast successfully',
        type: 'SUCCESS',
      })
      this.$gtag.event('remove-audiogram', { action: 'removed from timeline' })
      mixpanel.track('Remove Audiogram')
    }
    if (this.audiogramInTrack.length && this.audiogramInTrack[0].audiogramId === audiogramId) {
      await this.$store.dispatch('removeAudioAudiogram', {
        audioUid: this.audioUid,
        audiogramPosition: {
          ...this.audiogramInTrack[0],
        },
      })
      webengage?.track('remove-audiogram', {
        audioUid: this.audioUid,
        audiogramPosition: {
          ...this.audiogramInTrack[0],
        },
      })
      this.queryClient.invalidateQueries([audiogramQueryKeys.GET_AUDIOGRAMS, this.networkId, this.orientation])
      this.$store.commit('unselectAudiogram')
      this.$store.commit('unsetAudiogramInAudioTrack')
      this.pushNotification({
        text: 'Audiogram removed from track successfully',
        type: 'SUCCESS',
      })
    }
  }

  get filterText() {
    return this.orientation === orientationStatus.LANDSCAPE
      ? 'landscape'
      : this.orientation === orientationStatus.SQUARE
      ? 'square'
      : 'portrait'
  }
}
